import * as Sentry from "@sentry/vue";
import type { App } from "vue";
import type { Router } from "vue-router";

export const initSentry = (app: App, router: Router) => {
  const enableSentry = import.meta.env.VITE_SENTRY;

  if (enableSentry !== "false" && import.meta.env.VITE_SENTRY_DSN !== "") {
    console.log("Starting sentry...");

    Sentry.init({
      app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      release: import.meta.env.VITE_VERSION,
      integrations: [Sentry.browserTracingIntegration({ router })],
      ignoreErrors: [
        /^Navigation canceled from/,
        /^Avoided redundant navigation to current location/,
        /.+onesignal.+/gim,
        "AxiosError: Request failed with status code 403"
      ],
      environment: import.meta.env.MODE
    });
  }
};
